<template>
  <BaseLayout>
    <section class="self-center flex flex-col items-center xl:items-start xl:mb-10 w-10/12 xl:w-11/12">
      <section class="self-center xl:w-1/3 flex flex-col xl:mt-5">
        <div class="text-2xl xl:text-3xl font-bold mt-6 flex items-center">
          Gift Card
          <div class="h-1 w-24 bg-gray-800 ml-4" />
        </div>

        <div class="mt-3 xl:mt-6 xl:mb-10 mb-6 text-sm xl:text-base leading-6">
          This self photo studio gift card is the perfect gift for friends and family. <br>
          Gift cards are delivered by email and contain instructions to redeem them at checkout.
          Our gift cards have no additional processing fees and is valid for 6 months after purchase.
        </div>

        <div class="flex flex-col xl:flex-row xl:justify-between items-center xl:items-start xl:mb-8 xl:mx-6">
          <template v-if="isGettingServices">
            <SkeletonCard
              v-for="i in 2"
              :key="i"
            />
          </template>
          <template v-else>
            <div
              v-for="service in services"
              :key="service.id"
              class="mb-6 xl:mb-0 xl:mr-4 w-64 shadow rounded-md"
            >
              <div class="rounded-t">
                <img
                  v-if="service.id === oneSession"
                  src="~@/assets/images/service-banner-1.jpeg"
                  class="w-full h-52 object-cover rounded-t"
                  alt=""
                >
                <img
                  v-else-if="service.id === twoSession"
                  src="~@/assets/images/service-banner-2.jpeg"
                  class="w-full h-52 object-cover rounded-t"
                  alt=""
                >
              </div>

              <div class="py-4 px-6 text-sm xl:text-md">
                <div class="text-xl font-bold">
                  {{ service.name }}
                </div>
                <div class="h-0.5 w-full bg-gray-100 my-2 xl:my-3" />
                <div class="mb-1">
                  {{ service.sessionDuration }} minutes photo session
                </div>
                <div class="mb-1">
                  {{ service.selectionDuration }} minutes photo selection
                </div>
                <div class="mb-1">
                  {{ service.printedPhotos }} printed photos
                </div>
                <div class="mb-5">
                  All softcopy files
                </div>

                <Button
                  type="button"
                  class="w-full"
                  @click="toGiftSessionFormPage(service.id)"
                >
                  Purchase
                </Button>
              </div>
            </div>
          </template>
        </div>
      </section>

      <div class="flex flex-row flex-wrap justify-center xl:w-10/12 self-center mt-8">
        <img
          v-for="(banner, index) in banners"
          :key="index"
          :src="banner"
          class="banner"
          alt=""
        >
      </div>
      <div class="mt-8" />
    </section>
  </BaseLayout>
</template>

<style scoped>
.banner {
  @apply m-1.5;
  width: 23.5%;
}

@media (max-width: 768px) {
  .banner {
    @apply m-1;
    width: 46%;
  }
}
</style>

<script setup>
import BaseLayout from '@/components/BaseLayout'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SkeletonCard from '@/components/SkeletonCard'
import Button from '@/components/Button'
import config from '@/constant/config'
import banner1 from '@/assets/images/gift-banner/banner-1.jpg'
import banner2 from '@/assets/images/gift-banner/banner-2.jpg'
import banner3 from '@/assets/images/gift-banner/banner-3.jpg'
import banner4 from '@/assets/images/gift-banner/banner-4.jpg'
import banner5 from '@/assets/images/gift-banner/banner-5.jpg'
import banner6 from '@/assets/images/gift-banner/banner-6.jpg'
import banner7 from '@/assets/images/gift-banner/banner-7.jpg'
import banner8 from '@/assets/images/gift-banner/banner-8.jpg'
import banner9 from '@/assets/images/gift-banner/banner-9.jpg'
import banner10 from '@/assets/images/gift-banner/banner-10.jpg'
import banner11 from '@/assets/images/gift-banner/banner-11.jpg'
import banner12 from '@/assets/images/gift-banner/banner-12.jpg'

const { oneSession, twoSession } = config.service

const banners = [
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  banner9,
  banner10,
  banner11,
  banner12
]

const router = useRouter()
const store = useStore()
const services = ref([])
const isGettingServices = ref(false)

const toGiftSessionFormPage = serviceId => {
  router.push({
    name: 'GiftSessionForm',
    params: { serviceId }
  })
}

const getServices = () => {
  isGettingServices.value = true
  store.dispatch('getServices', {
    onSuccess: res => {
      isGettingServices.value = false
      services.value = res.data.data
    },
    onFail: () => {
      isGettingServices.value = false
      store.dispatch('toastGeneralError')
    }
  })
}

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
  getServices()
})

</script>
