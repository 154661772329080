<template>
  <button :class="className">
    <slot />
  </button>
</template>

<style scoped>
.disabled {
  @apply py-2 rounded-sm text-white bg-gray-400 cursor-default pointer-events-none border border-gray-400;
}

.filled {
  @apply py-2 rounded-sm text-white bg-gray-800 border border-gray-800 hover:bg-gray-700 transition ease-linear cursor-pointer;
}

.outlined {
  @apply py-2 rounded-sm text-gray-800 bg-white border border-gray-800 hover:bg-gray-100 transition ease-linear cursor-pointer;
}

.slide,
.lg-slide {
  @apply py-2 rounded-sm text-white xl:text-gray-800 bg-gray-800 xl:bg-white border border-gray-800 transition duration-200 ease-linear hover:text-white cursor-pointer;
}

.slide:hover,
.slide:focus {
  box-shadow: inset 15em 0 0 0 #1f2937;
  color: #fff;
}

.lg-slide:hover,
.lg-slide:focus {
  box-shadow: inset 35em 0 0 0 #1f2937;
  color: #fff;
}
</style>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  lg: {
    type: Boolean,
    default: false,
    required: false
  },
  filled: {
    type: Boolean,
    default: false,
    required: false
  },
  outlined: {
    type: Boolean,
    default: false,
    required: false
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false
  }
})

const className = computed(() => {
  if (props.disabled) return 'disabled'
  if (props.lg) return 'lg-slide'
  if (props.filled) return 'filled'
  if (props.outlined) return 'outlined'
  return 'slide'
})
</script>