<template>
  <BaseLayout>
    <section class="self-center flex flex-col items-center xl:items-start xl:mb-10">
      <div class="w-10/12">
        <div class="text-2xl xl:text-3xl font-bold mt-6 xl:mt-10 flex items-center">
          Our Services
          <div class="h-1 w-24 bg-gray-800 ml-4" />
        </div>

        <div class="mt-4 mb-8 text-sm xl:text-md flex">
          <input
            v-model="isAgreedToPolicy"
            id="checkbox"
            type="checkbox"
          >
          <label
            class="form-check-label inline-block text-gray-800"
            for="checkbox"
          >
            I have read and agreed to
            <RouterLink
              :to="config.page.studioPolicy"
              class="underline text-blue-800"
            >
              Futusut Studios' policy and protocols
            </RouterLink>
          </label>
        </div>
      </div>

      <div class="xl:flex xl:mb-8">
        <template v-if="isGettingServices">
          <SkeletonCard
            v-for="i in 2"
            :key="i"
          />
        </template>
        <template v-else>
          <div
            v-for="service in services"
            :key="service.id"
            class="mb-6 xl:mb-0 xl:mr-8 w-60 shadow rounded-md"
          >
            <div class="rounded-t">
              <img
                v-if="service.id === oneSession"
                src="~@/assets/images/service-banner-1.jpeg"
                class="w-full h-52 object-cover rounded-t"
                alt=""
              >
              <img
                v-else-if="service.id === twoSession"
                src="~@/assets/images/service-banner-2.jpeg"
                class="w-full h-52 object-cover rounded-t"
                alt=""
              >
            </div>

            <div class="py-4 px-6">
              <div class="text-xl font-bold">
                {{ service.name }}
              </div>
              <div class="h-0.5 w-full bg-gray-100 my-3" />
              <div class="mb-1">
                {{ service.sessionDuration }} minutes
              </div>
              <div class="mb-5">
                {{ numberFormatter(service.price, 'Rp.') }}
              </div>

              <Button
                type="buton"
                class="w-full"
                :disabled="!isAgreedToPolicy"
                @click="toBookDetailPage(service.id)"
              >
                Book
              </Button>
            </div>
          </div>
        </template>
      </div>
    </section>
  </BaseLayout>
</template>

<style scoped>
#checkbox {
  @apply h-4 w-4 rounded-sm focus:outline-none transition duration-200 mt-1 align-top mr-2 cursor-pointer;
  accent-color: #1f2937;
}
</style>

<script setup>
import BaseLayout from '@/components/BaseLayout'
import Button from '@/components/Button'
import { numberFormatter } from '@/utils/formatter'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SkeletonCard from '@/components/SkeletonCard'
import config from '@/constant/config'

const { oneSession, twoSession } = config.service

const router = useRouter()
const store = useStore()
const services = ref([])
const isAgreedToPolicy = ref(false)
const isGettingServices = ref(false)

const toBookDetailPage = serviceId => {
  if (!isAgreedToPolicy.value) return
  router.push({
    name: 'BookDetail',
    params: { serviceId }
  })
}

const getServices = () => {
  isGettingServices.value = true
  store.dispatch('getServices', {
    onSuccess: res => {
      isGettingServices.value = false
      services.value = res.data.data
    },
    onFail: () => {
      isGettingServices.value = false
      store.dispatch('toastGeneralError')
    }
  })
}

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
  getServices()
})
</script>
