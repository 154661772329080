<template>
  <div>
    <Transition name="fade">
      <div
        v-if="props.visible"
        class="fixed top-0 left-0 bg-black opacity-40 h-full w-full"
        @click="emit('update:visible', false)"
      />
    </Transition>
    <Transition :name="transitionName">
      <div
        v-if="props.visible"
        :class="modalClass"
      >
        <div
          v-if="isMobile"
          class="slot-container"
        >
          <slot />
        </div>
        <slot v-else />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.slot-container {
  max-height: 80vh;
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform-origin: 0 50%;
    transform: scale(0) translate(-50%, -50%);
  }
  50% {
    transform-origin: 0 50%;
    transform: scale(1.1) translate(-50%, -50%);
  }
  100% {
    transform-origin: 0 50%;
    transform: scale(1) translate(-50%, -50%);
  }
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes grow {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.grow-enter-active {
  animation: grow 0.3s;
}
.grow-leave-active {
  animation: grow 0.3s reverse;
}
</style>

<script setup>
import { defineProps, defineEmits, computed, watch } from 'vue'
import useResponsive from '@/composable/responsive'

const { isMobile } = useResponsive()

const emit = defineEmits(['confirmPay', 'update:visible'])

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  }
})

const modalClass = computed(() => isMobile
  ? 'fixed left-0 bottom-0 transform p-8 w-full bg-white flex-col rounded-t-3xl'
  : 'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-8 w-1/4 bg-white rounded-lg flex-col')

const transitionName = computed(() => isMobile ? 'grow' : 'bounce')

watch(() => props.visible, () => {
  const body = document.querySelector('body')
  if (props.visible) {
    body.style.overflowY = 'hidden'
  } else {
    body.style.overflowY = 'auto'
  }
})
</script>
