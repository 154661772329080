<template>
  <ModalLayout v-model:visible="computeVisible">
    <div class="font-bold text-2xl">
      Booking Confirmation
    </div>
    <div class="w-full h-0.5 bg-gray-200 my-3" />

    <div class="text-xl font-semibold">
      {{ service.name }}
    </div>
    <div class="mt-1">
      {{ bookingTimeString }}
    </div>

    <div class="mt-3 font-bold">
      Name
    </div>
    <div>
      {{ form.name }}
    </div>

    <div class="mt-1 font-bold">
      Phone
    </div>
    <div>
      {{ form.phone }}
    </div>

    <div class="mt-1 font-bold">
      Email
    </div>
    <div>
      {{ form.email }}
    </div>

    <div class="mt-3 font-bold">
      Total Price
    </div>
    <div v-if="totalPrice > 0">
      {{ numberFormatter(totalPrice, 'Rp.') }}
    </div>
    <div v-else class="text-green-600">
      Free
    </div>

    <div class="flex justify-end mt-6">
      <Button
        outlined
        class="mx-4 w-1/4"
        @click="emit('update:visible', false)"
      >
        Cancel
      </Button>
      <Button
        filled
        class="w-1/4"
        @click="emit('doPay')"
      >
        Pay
      </Button>
    </div>
  </ModalLayout>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import { formatDate, formatTime } from '@/utils/date'
import { numberFormatter } from '@/utils/formatter'
import ModalLayout from '@/components/ModalLayout'
import Button from '@/components/Button'

const DATE_FORMAT = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const emit = defineEmits(['confirmPay', 'update:visible', 'doPay'])

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  form: {
    type: Object,
    required: true
  },
  service: {
    type: Object,
    required: true
  },
  totalPrice: {
    type: Number,
    required: true
  }
})

const store = useStore()

const computeVisible = computed({
  get: () => props.visible,
  set: value => emit('update:visible', value)
})

const currentBook = computed(() => store.getters.currentBook || {})

const totalServiceDurationMillis = computed(() => {
  const { sessionDuration, selectionDuration } = props.service
  return (sessionDuration + selectionDuration) * 60 * 1000
})

const bookingTimeString = computed(() => {
  const first = currentBook.value.bookingTime[0]
  return `${formatDate(first, DATE_FORMAT)}
      ${formatTime(first)} - ${formatTime(first + totalServiceDurationMillis.value)}`
})
</script>
