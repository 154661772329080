<template>
  <div class="mb-6 xl:mb-0 xl:mr-8 shadow rounded-md h-80">
    <div class="w-56 h-2/5 bg-gray-300 rounded-t-md animate-pulse" />
    <div class="py-4 px-6 flex flex-col h-3/5">
      <div class="text-xl font-semibold bg-gray-300 h-6 animate-pulse rounded-xl mb-3" />
      <div class="text-xl font-semibold bg-gray-300 h-6 animate-pulse rounded-xl mb-3" />
      <div class="text-xl font-semibold bg-gray-300 h-6 animate-pulse rounded-xl" />
      <div class="text-xl font-semibold bg-gray-300 h-9 animate-pulse rounded mt-auto" />
    </div>
  </div>
</template>

<script setup />
