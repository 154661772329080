<template>
  <BaseLayout>
    <section
      v-if="service.id"
      class="flex flex-col px-6 xl:px-0 xl:w-2/3 xl:mx-auto my-5 xl:my-10"
    >
      <form
        class="flex flex-col xl:flex-row"
        @submit="pay"
      >
        <section class="grid grid-cols-4 gap-3 xl:w-7/12">
          <div class="text-2xl font-bold col-span-4">
            Fill out your details
          </div>
          <div class="my-3 h-0.5 bg-gray-200 w-full col-span-4" />

          <div class="col-span-4">
            <div class="mb-3">
              Sender Name
            </div>
            <div :class="{
              'px-4 py-2 border border-gray-300 rounded-md': true,
              'bg-gray-100': isDisabledForm
            }">
              <input
                v-model="form.senderName"
                type="text"
                class="outline-none w-full"
                placeholder="Input your name"
                :disabled="isDisabledForm"
              >
            </div>
            <div class="text-xs text-red-400 mt-2">
              {{ error.senderName }}
            </div>
          </div>

          <div class="col-span-4">
            <div class="mb-3">
              Sender Email
            </div>
            <div :class="{
              'px-4 py-2 border border-gray-300 rounded-md': true,
              'bg-gray-100': isDisabledForm
            }">
              <input
                v-model="form.senderEmail"
                type="text"
                class="outline-none w-full"
                placeholder="Input your email"
                :disabled="isDisabledForm"
              >
            </div>
            <div class="text-xs text-red-400 mt-2">
              {{ error.senderEmail }}
            </div>
          </div>

          <div class="col-span-4">
            <div class="mb-3">
              Recipient Name
            </div>
            <div :class="{
              'px-4 py-2 border border-gray-300 rounded-md': true,
              'bg-gray-100': isDisabledForm
            }">
              <input
                v-model="form.recipientName"
                type="text"
                class="outline-none w-full"
                placeholder="Input your email"
                :disabled="isDisabledForm"
              >
            </div>
            <div class="text-xs text-red-400 mt-2">
              {{ error.recipientName }}
            </div>
          </div>

          <div class="col-span-4">
            <div class="mb-3">
              Recipient Email
            </div>
            <div :class="{
              'px-4 py-2 border border-gray-300 rounded-md': true,
              'bg-gray-100': isDisabledForm
            }">
              <input
                v-model="form.recipientEmail"
                type="text"
                class="outline-none w-full"
                placeholder="Input your email"
                :disabled="isDisabledForm"
              >
            </div>
            <div class="text-xs text-red-400 mt-2">
              {{ error.recipientEmail }}
            </div>
          </div>

          <div class="col-span-4">
            <div class="mb-3">
              Personal Message
            </div>
            <div :class="{
              'px-4 py-2 border border-gray-300 rounded-md': true,
              'bg-gray-100': isDisabledForm
            }">
              <textarea
                v-model="form.personalMessage"
                class="outline-none w-full"
                placeholder="Add additional message"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </section>

        <section class="xl:ml-6 xl:w-5/12 mt-12 xl:mt-0">
          <div class="text-2xl font-bold col-span-1">
            Order Summary
          </div>
          <div class="my-6 h-0.5 bg-gray-200 w-full col-span-1" />

          <div class="px-4">
            <div class="text-xl font-semibold">
              {{ service.name }}
            </div>
            <div class="mt-1">
              Valid for {{ service.pax }} people
            </div>
            <div class="mt-1">
              {{ service.sessionDuration }} minutes photo session
            </div>
            <div class="mt-1">
              {{ service.selectionDuration }} minutes photo selection
            </div>
            <div class="mt-1">
              {{ service.printedPhotos }} printed photo's
            </div>

            <div class="flex justify-between mt-4">
              <div>{{ service.name }}</div>
              <div>{{ numberFormatter(service.giftPrice, 'Rp.') }}</div>
            </div>

            <div
              v-if="softcopyAddon?.id"
              class="flex justify-between"
            >
              <div>{{ softcopyAddon.name }}</div>
              <div>{{ numberFormatter(softcopyAddon.price, 'Rp.') }}</div>
            </div>

            <div
              v-if="giftDiscountPromo?.id"
              class="flex justify-between mb-4 text-green-600"
            >
              <div>{{ giftDiscountPromo.name }}</div>
              <div>{{ numberFormatter(giftDiscountPrice, 'Rp.') }}</div>
            </div>

            <div class="my-3 h-0.5 bg-gray-700 w-full" />

            <div
              v-if="totalprice"
              class="flex justify-between my-4 font-bold"
            >
              <div>Total price:</div>
              <div>{{ numberFormatter(totalprice, 'Rp.') }}</div>
            </div>

            <Button
              lg
              type="submit"
              class="w-full"
            >
              Purchase Gift
            </Button>
          </div>
        </section>
      </form>

      <PayGiftConfirmationModal
        v-model:visible="visiblePayGiftConfirmationModal"
        :form="form"
        :service="service"
        @confirmPay="createGift"
      />
    </section>
  </BaseLayout>
</template>

<script setup>
import BaseLayout from '@/components/BaseLayout'
import PayGiftConfirmationModal from '@/components/PayGiftConfirmationModal'
import Button from '@/components/Button'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { numberFormatter } from '@/utils/formatter'
import { isValidEmail } from '@/utils/validation'
import { popupCenter } from '@/utils/window'
import useResponsive from '@/composable/responsive'

const route = useRoute()
const store = useStore()

const { isMobile } = useResponsive()

const service = ref({})
const addOns = ref([])
const paymentUrl = ref('')
const isDisabledForm = ref(false)
const form = ref({
  senderName: '',
  senderEmail: '',
  recipientName: '',
  recipientEmail: '',
  personalMessage: ''
})
const error = ref({
  senderName: '',
  senderEmail: '',
  recipientName: '',
  recipientEmail: ''
})
const formRequired = ref({
  senderName: true,
  senderEmail: true,
  recipientName: true,
  recipientEmail: true,
  personalMessage: false
})
const visiblePayGiftConfirmationModal = ref(false)

const serviceId = computed(() => route.params.serviceId || '')

const softcopyAddon = computed(() => addOns.value.find(addon => addon.id === 'GIFT_SOFTCOPY_ADDONS') || {})

const totalprice = computed(() => softcopyAddon.value.price + service.value.giftPrice + giftDiscountPrice.value)

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const getService = () => {
  store.dispatch('getService', {
    payload: {
      serviceId: serviceId.value
    },
    onSuccess: res => {
      service.value = res.data.data
      getInternalPromos()
    }
  })
}

const getServiceAddOns = () => {
  store.dispatch('getServiceAddOns', {
    onSuccess: res => {
      addOns.value = res.data.data
    }
  })
}

const cleanErrors = () => {
  Object.keys(error.value).forEach(e => {
    error.value[e] = ''
  })
}

const isValidForm = () => {
  if (!isValidEmail(form.value.recipientEmail)) {
    error.value.recipientEmail = 'Must be valid email'
  }
  if (!isValidEmail(form.value.senderEmail)) {
    error.value.senderEmail = 'Must be valid email'
  }

  Object.keys(error.value).forEach(key => {
    if (formRequired.value[key] && !form.value[key]) {
      error.value[key] = 'Must be filled'
    }
  })

  return !Object.keys(error.value)
    .filter(e => error.value[e])
    .length
}

const pay = e => {
  e.preventDefault()
  cleanErrors()

  if (paymentUrl.value) {
    openPaymentUrl()
    return
  }

  if (isValidForm()) {
    visiblePayGiftConfirmationModal.value = true
    return
  }
  scrollToTop()
}

const createGift = () => {
  store.dispatch('saveGift', {
    payload: {
      form: {
        ...form.value,
        serviceId: service.value.id
      }
    },
    onSuccess: createGiftOnSuccess,
    onFail: () => {
      store.dispatch('toastGeneralError')
      visiblePayGiftConfirmationModal.value = false
    }
  })
}

const createGiftOnSuccess = res => {
  paymentUrl.value = res.data.paymentUrl
  visiblePayGiftConfirmationModal.value = false
  isDisabledForm.value = true
  openPaymentUrl()
}

const openPaymentUrl = () => {
  if (!isMobile) {
    popupCenter({
      url: paymentUrl.value,
      title: 'Futusut payment',
      w: 1200,
      h: 1000
    })
  } else {
    setTimeout(() => {
      window.location.href = paymentUrl.value
    }, 250)
  }
}

const internalPromos = ref([])
const giftDiscountPromo = computed(() => internalPromos.value.find(internalPromo => internalPromo.id === 'GIFT_DISCOUNT_PROMO') || {})

const giftDiscountPrice = computed(() => {
  if (!giftDiscountPromo.value.id) return 0

  if (giftDiscountPromo.value.discountType === 'PERCENTAGE') {
    const totalPrice = service.value.giftPrice + softcopyAddon.value.price
    return Math.floor(totalPrice * (giftDiscountPromo.value.value / 100)) * -1
  }
  return giftDiscountPromo.value.value * -1
})

const getInternalPromos = () => {
  store.dispatch('getInternalPromos', {
    payload: {
      serviceId: service.value.id
    },
    onSuccess: res => {
      internalPromos.value = res.data.data
    }
  })
}

onMounted(() => {
  scrollToTop()
  getService()
  getServiceAddOns()
})
</script>
