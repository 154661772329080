import api from '@/constant/api'
import axios from '@/axios'

const actions = {
  getGift({ commit }, { payload, onSuccess, onFail } = {}) {
    commit('setIsLoading', true)
    return axios.get(api.gift, { params: payload })
      .then(res => {
        commit('setIsLoading', false)
        onSuccess && onSuccess(res)
      })
      .catch(err => {
        commit('setIsLoading', false)
        onFail && onFail(err)
      })
  },
  getGiftDetail(_, { payload, onSuccess, onFail } = {}) {
    return axios.get(api.giftDetail, { params: payload })
      .then(res => {
        onSuccess && onSuccess(res)
      })
      .catch(err => {
        onFail && onFail(err)
      })
  },
  saveGift({ commit }, { payload, onSuccess, onFail } = {}) {
    commit('setIsLoading', true)
    return axios.post(api.gift, payload.form)
      .then(res => {
        commit('setIsLoading', false)
        onSuccess && onSuccess(res)
      })
      .catch(err => {
        commit('setIsLoading', false)
        onFail && onFail(err)
      })
  }
}

export default { actions }
