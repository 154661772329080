<template>
  <div class="flex flex-wrap justify-center xl:justify-start">
    <button
      v-for="time in bookingHours"
      :key="time.timestamp"
      type="button"
      :class="{
        'p-2 w-1/4 m-1 border border-gray-300 transition duration-200 ease-linear': true,
        'hover:bg-gray-200': time.available && !props.selectedTimes.includes(time.timestamp),
        'bg-gray-100 text-gray-400 cursor-default': !time.available,
        'bg-gray-800 hover:bg-gray-800 text-sky-50': time.available && props.selectedTimes.includes(time.timestamp),
        'border-sky-800 bg-white text-gray-800': currentCustomerBooking.bookingTime && currentCustomerBooking.bookingTime.includes(time.timestamp)
      }"
      @click="setSelectedTime(time)"
    >
      {{ time.text }}
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { toGmtPlusSeven } from '@/utils/date'
import config from '@/constant/config'

const TEN_MINUTES = 10 * 60 * 1000

const _13_15_MILLIS = ((13 * 60) + 15) * 60 * 1000
const _20_30_MILLIS = ((20 * 60) + 30) * 60 * 1000

const props = defineProps({
  selectedDate: {
    type: Date,
    required: true
  },
  selectedTimes: {
    type: Array,
    required: true
  },
  timeCount: {
    type: Number,
    required: true
  },
  serviceId: {
    type: String,
    required: true
  },
  onUpdateTimestamp: {
    type: Function,
    default: () => {}
  },
  bookingId: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:selectedTimes'])

const store = useStore()

const reservedBookings = ref([])

const isOneSessionService = computed(() => props.serviceId === 'SESSION_TYPE_ONE')

const isTuesdayUntilThursday = date => {
  return date.getDay() >= 2 && date.getDay() <= 4
}

const bookingHours = computed(() => config.bookingHours.map(toBookingHourInformation)
  .filter(shouldRemove_20_30)
  .filter(shouldOpenAtNoon)
  .map((b, index) => ({ ...b, index })))

const shouldRemove_20_30 = bookingHour => {
  if (isOneSessionService.value) {
    return bookingHour.originalTime !== _20_30_MILLIS
  }
  return true
}

const shouldOpenAtNoon = bookingHour => {
  if (isTuesdayUntilThursday(bookingHour.date)) {
    return bookingHour.originalTime >= _13_15_MILLIS
  }
  return true
}

const bufferHours = computed(() => config.bufferHourMillis.map(h => props.selectedDate.getTime() + h))

const reservedTimes = computed(() => (reservedBookings.value || []).flatMap(b => b.bookingTime))

const toBookingHourInformation = bookingHour => {
  const date = new Date(props.selectedDate.getTime() + bookingHour)

  const notReservedYet = !reservedTimes.value.includes(toGmtPlusSeven(date).getTime())
  const greaterThanCurrentTime = date.getTime() + TEN_MINUTES > new Date().getTime()
  const isReservedByCurrentCustomer = currentCustomerBooking.value.id && currentCustomerBooking.value.bookingTime.includes(date.getTime())

  return {
    originalTime: bookingHour,
    date,
    timestamp: date.getTime(),
    text: ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2),
    available: (notReservedYet && greaterThanCurrentTime) || isReservedByCurrentCustomer
  }
}

const setSelectedTime = time => {
  if (!time.available) return

  if (bufferHours.value.includes(time.timestamp) && props.timeCount > 1) {
    store.dispatch('toastInfo', `We are taking a break after ${time.text}. Please choose another time`)
    return
  }

  /**
   * Ex: time -> 10.55
   * Ex: bookingHours -> [10.30, 10.55, 11.20, 11.45, 12.10]
   * Service type one -> timeCount: 1 | Service type two -> timeCount: 2
   * Expected reservingTime value
   * - service type one -> [10.55]
   * - service type two -> [10.55, 11.20]
   */
  const reservingTime = bookingHours.value.slice(time.index, props.timeCount + time.index)
    .filter(t => t.available)
    .map(t => t.timestamp)

  /**
   * If service type one -> reservingTime length should be 1
   * If service type two -> reservingTime length should be 2
   * If not then there's unavailable time
   */
  if (reservingTime.length < props.timeCount) {

    /**
     * Case: when service type is two and user select the last booking time
     */
    if (time.index === bookingHours.value.length - 1) {
      store.dispatch('toastInfo', 'No more session available after ' + time.text)
      return
    }

    store.dispatch('toastInfo', 'Time is not available, please choose another time')
    return
  }

  maybeSetSelectedTime(reservingTime)
}

const maybeSetSelectedTime = reservingTime => {
  store.commit('setIsLoading', true)
  store.dispatch('isBookingTimesAvailable', {
    payload: {
      timestamps: reservingTime.map(t => toGmtPlusSeven(new Date(t)).getTime()),
      bookingId: currentCustomerBooking.value.id
    },
    onSuccess: res => {
      store.commit('setIsLoading', false)
      const availabilities = res.map(r => r.data.data) || []

      if (availabilities.some(a => !a)) {
        store.dispatch('toastInfo', 'Time is not available, please choose another time')
        getReservedBookings()
      } else {
        emit('update:selectedTimes', reservingTime)
        props.onUpdateTimestamp()
      }
    },
    onFail: () => {
      store.commit('setIsLoading', false)
      store.dispatch('toastGeneralError')
    }
  })
}

const currentCustomerBooking = computed(() => reservedBookings.value.find(b => b.id === props.bookingId) || {})

const getReservedBookings = () => {
  store.dispatch('getReservedBookingTimes', {
    payload: {
      timestampDate: toGmtPlusSeven(props.selectedDate).getTime()
    },
    onSuccess: res => {
      reservedBookings.value = res.data.data
      emit('update:selectedTimes', [])
    },
    onFail: () => {
      store.dispatch('toastGeneralError')
    }
  })
}

onMounted(() => {
  getReservedBookings()
})

watch(() => props.selectedDate, getReservedBookings)
</script>
