<template>
  <header class="flex items-center justify-between px-6 xl:px-40 py-6 border bottom-1 shadow">
    <RouterLink :to="home">
      <img
        src="~@/assets/images/logo.png"
        class="w-24 xl:w-40"
      />
    </RouterLink>

    <nav
      v-if="!isMobile"
      class="flex"
    >
      <div
        v-for="page in PAGES"
        :key="page.text"
        :class="{ 'menu': true, 'active': isTabActive(page) }"
      >
        <RouterLink :to="page.path">
          {{ page.text  }}
        </RouterLink>
        <div class="line" />
      </div>
    </nav>

    <nav v-else>
      <BurgerMenu @click="visibleMobileMenu = true" />

      <transition
        enter-active-class="transition ease-in-out duration-300"
        enter-from-class="transform scale-x-0"
        leave-active-class="transition ease-in-out duration-300"
        leave-to-class="transform scale-x-0 origin-right"
      >
        <div
          v-if="visibleMobileMenu"
          class="z-10 fixed top-0 right-0 h-screen w-full bg-white py-8 px-10 flex flex-col origin-right"
        >
          <XIcon
            class="w-6 ml-auto"
            @click="visibleMobileMenu = false"
          />

          <nav class="flex flex-col justify-end text-right text-lg h-3/4">
            <div
              v-for="page in PAGES"
              :key="page.text"
              :class="{ 'mb-3 xl:mb-4 flex items-center justify-end': true, 'font-bold': isTabActive(page) }"
            >
              <div
                v-if="isTabActive(page)"
                class="h-2 w-2 rounded-xl bg-black mr-2.5"
              />
              <RouterLink :to="page.path">
                {{ page.text  }}
              </RouterLink>
            </div>
          </nav>
        </div>
      </transition>
    </nav>
  </header>
</template>

<style scoped>
.menu {
  @apply mr-10 font-medium relative pt-2;
}

.line {
  @apply h-1 bg-gray-800 rounded mt-2 text-center;
  transform: scaleX(0);
  transition: transform .2s ease;
  width: 80%;
  margin-left: auto;
  margin-right: auto; 
}

.active > .line {
  transform: scaleX(1);
}

.menu:hover .line {
  transform: scaleX(1);
}
</style>

<script setup>
import config from '@/constant/config'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { XIcon } from '@heroicons/vue/solid'
import useResponsive from '@/composable/responsive'
import BurgerMenu from '@/components/BurgerMenu'

const { home, about, contact, bookOnline, giftSession, pricelist } = config.page

const PAGES = [
  {
    path: home,
    text: 'Home'
  },
  {
    path: about,
    text: 'About'
  },
  {
    path: contact,
    text: 'Contact'
  },
  {
    path: bookOnline,
    text: 'Book Online'
  },
  {
    path: giftSession,
    text: 'Gift'
  },
  {
    path: pricelist,
    text: 'Pricelist'
  }
]

const { isMobile } = useResponsive()
const route = useRoute()
const visibleMobileMenu = ref(false)

const isTabActive = page => {
  return route.path.indexOf(page.path) > -1
}
</script>
