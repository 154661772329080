<template>
  <ModalLayout v-model:visible="computeVisible">
    <div class="font-bold text-2xl">
      Order Confirmation
    </div>
    <div class="w-full h-0.5 bg-gray-200 my-3" />

    <div class="mb-3">
      You will buy gift card for:
      <div class="font-semibold mt-3 mb-1">
        {{ service.name }}
      </div>
      <div class="mb-1 text-sm">
        {{ service.sessionDuration }} minutes photo session
      </div>
      <div class="mb-1 text-sm">
        {{ service.selectionDuration }} minutes photo selection
      </div>
      <div class="mb-1 text-sm">
        {{ service.printedPhotos }} printed photos
      </div>
      <div class="text-sm">
        All softcopy files
      </div>
      <div class="h-0.5 w-full bg-gray-100 my-3" />
    </div>

    <div class="mb-3">
      <div class="font-bold">
        Sender name
      </div>
      {{ props.form.senderName }}
    </div>

    <div class="mb-3">
      <div class="font-bold">
        Sender email
      </div>
      {{ props.form.senderEmail }}
    </div>

    <div class="mb-3">
      <div class="font-bold">
        Recipient name
      </div>
      {{ props.form.recipientName }}
    </div>

    <div class="mb-3">
      <div class="font-bold">
        Recipient email
      </div>
      {{ props.form.recipientEmail }}
    </div>

    <div class="flex justify-end mt-6">
      <Button
        outlined
        class="mx-4 w-1/4"
        @click="emit('update:visible', false)"
      >
        Cancel
      </Button>
      <Button
        filled
        class="w-1/4"
        @click="emit('confirmPay')"
      >
        Pay
      </Button>
    </div>
  </ModalLayout>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import ModalLayout from '@/components/ModalLayout'
import Button from '@/components/Button'

const emit = defineEmits(['confirmPay', 'update:visible'])

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  form: {
    type: Object,
    required: true
  },
  service: {
    type: Object,
    required: true
  }
})

const computeVisible = computed({
  get: () => props.visible,
  set: value => emit('update:visible', value)
})
</script>
