import api from '@/constant/api'
import axios from '@/axios'

const actions = {
  applyDiscount({ commit }, { payload, onSuccess, onFail } = {}) {
    commit('setIsLoading', true)
    return axios.get(api.discount, { params: payload })
      .then(res => {
        commit('setIsLoading', false)
        onSuccess && onSuccess(res)
      })
      .catch(err => {
        commit('setIsLoading', false)
        onFail && onFail(err)
      })
  },
  getDiscountDetail(_, { payload, onSuccess, onFail } = {}) {
    return axios.get(api.discountDetail, { params: payload })
      .then(res => onSuccess && onSuccess(res))
      .catch(err => onFail && onFail(err))
  }
}

export default { actions }
